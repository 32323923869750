import React from 'react';

import './Client.css';
import {withTranslation} from 'react-i18next';
import AlphabetLogo from "../../logos/AlphabetLogo";
import AvataLogo from "../../logos/AvataLogo";
import DelhaizeLogo from "../../logos/DelhaizeLogo";
import GateSoftLogo from "../../logos/GateSoftLogo";
import IndLogo from "../../logos/IndLogo";
import KpmgLogo from "../../logos/KpmgLogo";
import LoomisLogo from "../../logos/LoomisLogo";
import VanderlandeLogo from "../../logos/VanderlandeLogo";
import i18next from "i18next";


const Client = (props) => {
    let logo;

    if (props.name === 'delhaize') {
        logo = <DelhaizeLogo/>
    } else if (props.name === 'gatesoft') {
        logo = <GateSoftLogo/>
    } else if (props.name === 'kpmg') {
        logo = <KpmgLogo/>;
    } else if (props.name === 'loomis') {
        logo = <LoomisLogo/>
    } else if (props.name === 'vanderlande') {
        logo = <VanderlandeLogo/>
    } else if (props.name === 'alphabet') {
        logo = <AlphabetLogo/>
    } else if (props.name === 'avata') {
        logo = <AvataLogo/>
    }  else if (props.name === 'ind') {
        logo = <IndLogo/>
    }

    return (
        <div className="Client-Container">
            <div className="Logo-Container" style={{ left: `${props.left}`}}>
                {logo}
            </div>
            <div className="Client-Name">{i18next.t(`clients.${props.name}.name`)}</div>
            <div className="Client-Tags">{i18next.t(`clients.${props.name}.tags`)}</div>
        </div>
    );
}

export default withTranslation()(Client)
