import React, {Component} from "react";

import './ScrollArrow.css';
import ArrowScrollIcon from "../../components/icons/ArrowScrollIcon";
import ArrowRolloverScrollIcon from "../../components/icons/ArrowRolloverScrollIcon";

export class ScrollArrow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isHover: false
        };
    }

    onMouseEnterHandler() {
        this.setState({
            isHover: true
        });
    }

    onMouseLeaveHandler() {
        this.setState({
            isHover: false
        });
    }

    render() {
        return (
            <div onMouseEnter={ () => this.onMouseEnterHandler() }
                onMouseLeave={ () => this.onMouseLeaveHandler() }
                className="Scroll-Arrow-Container">
                {
                    this.state.isHover
                        ? <ArrowRolloverScrollIcon/>
                        : <ArrowScrollIcon/>
                }
            </div>
        );
    }
}