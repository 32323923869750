import React from 'react';

import './Intro.css';
import {withTranslation} from 'react-i18next';

function Intro({ t }) {
    return (
        <React.Fragment>
            <div className="Intro-Container">
                <div className="Intro-Text">{t('intro')}</div>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(Intro)
