import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as actions from '../../store/actions/index';

import './Layout.css';
import Header from "../../components/Header/Header";
import Home from "../../containers/Home/Home";
import Sun from "../../containers/Sun/Sun";
import Sidebar from "../../containers/Sidebar/Sidebar";
import Contact from "../../components/Contact/Contact";
import ContactOverlay from "../../components/ContactOverlay/ContactOverlay";

class Layout extends Component {

    onMouseEnterLayout() {
        this.props.onMouseEnter();
    }

    onMouseLeaveLayout() {
        this.props.onMouseLeave();
    }

    onMouseMoveOnLayout(event) {
        this.props.onMouseMove(event.clientX, event.clientY);
    }

    render() {
        let content;
        if (this.props.sunHasRisen) {
            content =
                <div className="Layout-Container Layout-Container-background">
                    <div className="Layout-Header-Container">
                        <Header/>
                    </div>
                    <div className="Layout-Background-Container">
                        <Sun/>
                    </div>
                    <div className="Layout-Content-Container content-fade-in">
                        <div>
                            <div className="Layout-Home-Container">
                                <Home/>
                            </div>
                            <div className="Layout-Contact-Container" id={'contact'}>
                                <Contact/>
                            </div>
                            <div className="Layout-Overlay-Contact-Container">
                                <ContactOverlay/>
                            </div>
                        </div>
                        <div className="Layout-Sidebar-Container">
                            <Sidebar/>
                        </div>
                    </div>
                </div>
        } else {
            content =
                <div className="Layout-Container background-rise">
                    <div className="Layout-Header-Container">
                        <Header/>
                    </div>
                    <div className="Layout-Background-Container">
                        <Sun/>
                    </div>
                </div>
        }

        return (
            <div
                onMouseEnter={() => this.onMouseEnterLayout()}
                onMouseLeave={() => this.onMouseLeaveLayout()}
                onMouseMove={(event) => this.onMouseMoveOnLayout(event)}>
                {content}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        sunHasRisen: state.sun.sunHasRisen,
        sunDiameter: state.sun.sunDiameter,
        sunDefaultX: state.sun.sunDefaultX,
        sunDefaultY: state.sun.sunDefaultY,
        sunTargetX: state.sun.sunTargetX,
        sunTargetY: state.sun.sunTargetY
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onMouseEnter: () => dispatch(actions.userActive()),
        onMouseLeave: () => dispatch(actions.userInactive()),
        onMouseMove: (x, y) => dispatch(actions.updateMousePosition(x, y))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
