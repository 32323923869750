import React, {Component} from "react";
import ArrowRolloverLogoRightIcon from "../../components/icons/ArrowRolloverLogoRightIcon";
import ArrowLogoRightIcon from "../../components/icons/ArrowLogoRightIcon";

export class RightArrow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isHover: false
        };
    }

    onMouseEnterHandler() {
        this.setState({
            isHover: true
        });
    }

    onMouseLeaveHandler() {
        this.setState({
            isHover: false
        });
    }

    render() {
        return (
            <div onMouseEnter={() => this.onMouseEnterHandler()}
                 onMouseLeave={() => this.onMouseLeaveHandler()}
                 className="Left-Arrow-Container">
                {
                    this.state.isHover
                        ? <ArrowRolloverLogoRightIcon/>
                        : <ArrowLogoRightIcon/>
                }
            </div>
        );
    }
}