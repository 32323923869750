import {updateObject} from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    sunIsRising: false,
    sunHasRisen: false,
    userActive: false,
    clientX: 0,
    clientY: 0
};

const sunRiseStart = (state, action) => {
    return updateObject(state, { sunIsRising: true, sunHasRisen: false });
};

const sunHasRisen = (state, action) => {
    return updateObject(state, { sunIsRising: false, sunHasRisen: true })
};

const userActive = (state, action) => {
    return updateObject(state, { userActive: true })
}

const userInactive = (state, action) => {
    return updateObject(state, {
        userActive: false,
        sunTargetX: state.sunDefaultX,
        sunTargetY: state.sunDefaultY
    })
}

const updateMousePosition = (state, action) => {
    const updatedState = {
        clientX: action.x,
        clientY: action.y
    }
    return updateObject(state, updatedState);
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SUN_RISE_START: return sunRiseStart(state, action);
        case actionTypes.SUN_RISE_FINISHED: return sunHasRisen(state, action);
        case actionTypes.USER_ACTIVE_START: return userActive(state, action);
        case actionTypes.USER_ACTIVE_FINISHED: return userInactive(state, action);
        case actionTypes.UPDATE_MOUSE_POSITION: return updateMousePosition(state, action);
        default:
            return state;
    }
};

export default reducer;
