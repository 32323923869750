import React from 'react';

import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import {withTranslation} from "react-i18next";

const navigationItems = ( { t } ) => (
    <ul className="NavigationItems">
        <NavigationItem link="services">{t('navigation.services')}</NavigationItem>
        <NavigationItem link="process">{t('navigation.process')}</NavigationItem>
        <NavigationItem link="clients">{t('navigation.clients')}</NavigationItem>
        <NavigationItem link="contact">{t('navigation.contact')}</NavigationItem>
    </ul>
);

export default withTranslation()(navigationItems);