import React, {Component} from 'react';
import {connect} from 'react-redux';
import Transition from "react-transition-group/Transition";

import * as actions from '../../store/actions/index';

import './Sun.css';

export class Sun extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSafari: false
        };
    }

    componentDidMount() {
        this.props.onLoad();
        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
        this.setState({
            isSafari: isSafari
        });
    }

    render() {
        let x = 0;
        let y = 0;

        if (this.props.userActive) {
            x = ((-100 / window.innerWidth) * this.props.clientX) + 50;
            y = ((-100 / window.innerHeight) * this.props.clientY) + 50;
        }

        let transition;
        let sunTrans = `translate3d(${x}px, ${y}px, 0px)`;
        let sunTransition = `transform 1s cubic-bezier(0,0,0.58,1)`;
        if (this.state.isSafari && this.props.userActive) {
            sunTransition = `transform 0.15s cubic-bezier(0,0,0.58,1)`;
        } else if (this.state.isSafari && !this.props.userActive) {
            sunTransition = `transform 1.0s cubic-bezier(0,0,0.58,1)`;
        }

        let sunStyle = {
            transition: sunTransition,
            WebkitTransform: sunTrans,
            transform: sunTrans
        };

        if (!this.props.sunHasRisen) {
            transition =
                <Transition
                    in={this.props.sunIsRising}
                    timeout={1000}
                    onEntered={() => {
                        this.props.onSunHasRisen();
                    }
                    }
                >
                    <React.Fragment>
                        <div className="Sun rise"/>
                    </React.Fragment>
                </Transition>;

        } else {
            transition =
                <Transition
                    in={this.props.sunHasRisen && this.props.userActive}
                    timeout={4000}
                >
                    <div className="Sun SunALife" style={sunStyle}/>
                </Transition>;
        }

        return (
            <div
                id="Sun"
                className="Sun-Container">
                {transition}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        sunIsRising: state.sun.sunIsRising,
        sunHasRisen: state.sun.sunHasRisen,
        userActive: state.sun.userActive,
        clientX: state.sun.clientX,
        clientY: state.sun.clientY
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => dispatch(actions.sunLoad()),
        onSunHasRisen: () => dispatch(actions.sunHasRisen()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sun);
