import React from 'react';

import './ContactOverlay.css';
import {withTranslation} from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import i18next from "i18next";

const ContactOverlay = () => {
    let email = i18next.t('contact.email')
    return (
        <div className="Contact-Overlay-Container">
            <div className="Contact-Overlay-Description">{i18next.t('contact.description')}</div>
            <div className="Contact-Overlay-Contact">
                <span>{i18next.t('contact.contact1')}</span><a className="Contact-Anchor-Tag" href={'mailto:' + email}><b>{email}</b></a>
                <div>
                    <span>{i18next.t('contact.contact2')}</span><span><b>{i18next.t('contact.phoneNumber')}</b></span>
                </div>
                <div>
                    <span>{i18next.t('contact.contact3')}</span><span><b>{i18next.t('contact.contact4')}</b></span>
                </div>
            </div>

            <div className="Contact-Overlay-Footer-Container">
                <div className="Contact-Overlay-Footer">
                    <div className="Contact-Overlay-Footer-Item">{i18next.t('contact.copyright')}</div>
                    <div className="Contact-Overlay-Footer-Item">{i18next.t('contact.privacy')}</div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ContactOverlay)