import * as actionTypes from './actionTypes';

export const sunRiseStart = () => {
    return {
        type: actionTypes.SUN_RISE_START
    };
};

export const sunRiseFinished = () => {
    return {
        type: actionTypes.SUN_RISE_FINISHED
    };
};

export const userActive = () => {
    return {
        type: actionTypes.USER_ACTIVE_START
    };
};

export const userInactive = () => {
    return {
        type: actionTypes.USER_ACTIVE_FINISHED
    };
};

export const sunLoad = () => {
    return dispatch => {
        dispatch(sunRiseStart());
    };
};

export const sunHasRisen = () => {
    return dispatch => {
        dispatch(sunRiseFinished());
    };
};

export const updateMousePosition = (x, y) => {
    return {
        type: actionTypes.UPDATE_MOUSE_POSITION,
        x: x,
        y: y
    }
}
