import React from 'react';

import './CustomSoftware.css';
import {withTranslation} from 'react-i18next';
import CustomSoftwareIcon from "../../icons/CustomSoftwareIcon";

function CustomSoftware({ t }) {
    return (
        <div className="CustomSoftware-Container">
            <div className="CustomSoftwareIcon-Container">
                <CustomSoftwareIcon/>
            </div>
            <div className="CustomSoftware-Title">{t('services.customSoftware.title')}</div>
            <div className="CustomSoftware-Description">{t('services.customSoftware.description')}</div>
        </div>
    );
}

export default withTranslation()(CustomSoftware)