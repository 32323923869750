import React, {Component} from 'react';

import './Process.css';
import {withTranslation} from 'react-i18next';
import ProcessStep from "./ProcessStep/ProcessStep";

class Process extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        }
        this.toggleStep = this.toggleStep.bind(this)
    }

    toggleStep(index) {
        this.setState(() => {
            return {activeIndex: index}
        });
    }

    render() {
        const {t} = this.props
        const {activeIndex} = this.state;
        const steps = [
            {
                id: "1",
                name: "definition"
            },
            {
                id: "2",
                name: "design"
            },
            {
                id: "3",
                name: "build"
            },
            {
                id: "4",
                name: "support"
            },
            {
                id: "5",
                name: "growth"
            }
        ];

        let content = steps.map((step, index) => {
            return (
                <ProcessStep key={index} name={step.name} activeIndex={activeIndex} index={index}
                             parentCallback={this.toggleStep}/>
            );
        });

        return (
            <div className="Process-Container">
                <div className="Process-Title">{t('process.title')}</div>
                {content}
                <hr className="ProcessStep-Horizontal-Rule"/>
            </div>
        )
    }
}

export default withTranslation()(Process)
