import React from 'react';

import './MobileApps.css';
import {withTranslation} from 'react-i18next';
import MobileAppIcon from "../../icons/MobileAppIcon";

function MobileApps({ t }) {
    return (
        <div className="MobileApps-Container">
            <div className="MobileAppIcon-Container">
                <MobileAppIcon/>
            </div>
            <div className="MobileApps-Title">{t('services.mobileApps.title')}</div>
            <div className="MobileApps-Description">{t('services.mobileApps.description')}</div>
        </div>
    );
}

export default withTranslation()(MobileApps)