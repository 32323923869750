import React, {Component} from 'react'

import './LanguageSelector.css'
import * as actions from "../../store/actions";
import {connect} from "react-redux";

export class LanguageSelector extends Component {

    changeLanguage(lng) {
        this.props.onSelectLanguage(lng)
    }

    render() {
        return (
            <div className="LanguageSelector">
                <button
                    type="button"
                    className="LanguageSelector-Button"
                    disabled={this.props.selectedLanguage === 'nl'}
                    onClick={ () => { this.changeLanguage('nl') }}>NL</button>
                <div className="LanguageSelector-Divider"> | </div>
                <button
                    type="button"
                    className="LanguageSelector-Button"
                    disabled={this.props.selectedLanguage === 'en'}
                    onClick={ () => { this.changeLanguage('en') }}>EN</button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedLanguage: state.menu.selectedLanguage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectLanguage: (lng) => dispatch( actions.updateLanguage(lng))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( LanguageSelector );