import {updateObject} from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    selectedLanguage: 'nl',
    selectedTopic: 'services'
};

const updateLanguageSuccess = (state, action) => {
    const updatedState = {
        selectedLanguage: action.lng
    }
    return updateObject(state, updatedState);
}

const updateTopicSelection = (state, action) => {
    const updatedState = {
        selectedTopic: action.topic
    }
    return updateObject(state, updatedState);
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.MENU_UPDATE_LANGUAGE_SUCCESS: return updateLanguageSuccess(state, action);
        case actionTypes.MENU_UPDATE_TOPIC: return updateTopicSelection(state, action);
        default:
            return state;
    }
};

export default reducer;
