import React from 'react';

import './Services.css';
import { withTranslation } from 'react-i18next';
import CustomSoftware from "./CustomSoftware/CustomSoftware";
import SoftwareAsAService from "./SofwareAsAService/SoftwareAsAService";
import MobileApps from "./MobileApps/MobileApps";

function Services({ t }) {
    return (
        <div className="Services-Container">
            <div className="Services-Row">
                <div className="Services-Column">
                    <div className="Services-Title">{t('services.title')}</div>
                    <SoftwareAsAService/>
                </div>
                <div className="Services-Column">
                    <CustomSoftware/>
                    <MobileApps/>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(Services)