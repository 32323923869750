import React from 'react'
import { ReactComponent as IndSvg} from '../../assets/svgs/clients/IND.svg';

const IndLogo = () => (
    <div>
        <IndSvg/>
    </div>
)

export default IndLogo
