import React from 'react';

import './SoftwareAsAService.css';
import {withTranslation} from 'react-i18next';
import SaasIcon from "../../icons/SaasIcon";

function SoftwareAsAService({ t }) {
    return (
        <div className="SoftwareAsAService-Container">
            <div className="SoftwareAsAServiceIcon-Container">
                <SaasIcon/>
            </div>
            <div className="SoftwareAsAService-Title">{t('services.softwareAsAService.title')}</div>
            <div className="SoftwareAsAService-Description">{t('services.softwareAsAService.description')}</div>
        </div>
    );
}

export default withTranslation()(SoftwareAsAService)