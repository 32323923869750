import React, {Component} from 'react';
import Intro from '../../components/Intro/Intro';

import './Home.css'
import Payoff from "../../components/Payoff/Payoff";
import Services from "../../components/Services/Services";
import Process from "../Process/Process";
import Clients from "../../components/Clients/Clients";
import {Link} from 'react-scroll'
import {ScrollArrow} from "../ScrollArrow/ScrollArrow";

export class Home extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="Home-Payoff-Container">
                    <Payoff/>
                </div>
                <div className="Home-Intro-Container">
                    <Intro/>
                </div>
                <div className="Next-Section-Intro-Arrow">
                    <Link to="services" spy={true} smooth={true}>
                        <div className="Home-Scroll-Arrow-Container">
                            <ScrollArrow/>
                        </div>
                    </Link>
                </div>
                <div className="Home-Services-Container" id={'services'}>
                    <Services/>
                </div>
                <div className="Next-Section-Services-Arrow">
                    <Link to="process" spy={true} smooth={true}>
                        <div className="Home-Scroll-Arrow-Container">
                            <ScrollArrow/>
                        </div>
                    </Link>
                </div>
                <div className="Home-Process-Container" id={'process'}>
                    <Process/>
                </div>
                <div className="Next-Section-Process-Arrow">
                    <Link to="clients" spy={true} smooth={true}>
                        <div className="Home-Scroll-Arrow-Container">
                            <ScrollArrow/>
                        </div>
                    </Link>
                </div>
                <div className="Home-Clients-Container" id={'clients'}>
                    <Clients/>
                </div>
                <div className="Next-Section-Clients-Arrow">
                    <Link to="contact" spy={true} smooth={true}>
                        <div className="Home-Scroll-Arrow-Container">
                            <ScrollArrow/>
                        </div>
                    </Link>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;