import React from 'react';

import './NavigationItem.css';
import {Link} from "react-scroll";

const navigationItem = ( props ) => (
    <li className="NavigationItem">
        <Link to={props.link} spy={true} smooth={true}>{props.children}</Link>
    </li>
);

export default navigationItem;