import React, {Component} from 'react';

import './ProcessStep.css';
import {withTranslation} from 'react-i18next';
import {Collapse} from "react-collapse/lib/Collapse";
import ArrowRolloverUnfoldIcon from "../../../components/icons/ArrowRolloverUnfoldIcon";
import ArrowUnfoldIcon from "../../../components/icons/ArrowUnfoldIcon";
import ArrowClickedUnfoldIcon from "../../../components/icons/ArrowClickedUnfoldIcon";

class ProcessStep extends Component {

    constructor(props) {
        super(props);
        this.doParentCallback = this.doParentCallBack.bind(this)
        this.state = {
            isHover: false
        };
    }

    onMouseEnterHandler() {
        this.setState({
            isHover: true
        });
    }

    onMouseLeaveHandler() {
        this.setState({
            isHover: false
        });
    }

    doParentCallBack(index) {
        this.props.parentCallback(index)
    }

    showArrow(index, hover) {
        if (this.props.activeIndex === index) {
            return (
                <div className="Arrow-Unfold-Container Rotate-Arrow">
                    <ArrowClickedUnfoldIcon/>
                </div>
            );
        } else if (hover === true) {
            return (
                <div className="Arrow-Unfold-Container">
                    <ArrowRolloverUnfoldIcon/>
                </div>
            );
        } else {
            return (
                <div className="Arrow-Unfold-Container">
                    <ArrowUnfoldIcon/>
                </div>
            );
        }
    }

    render() {
        const { t } = this.props

        return (
            <div className="ProcessStep-Container"
                 onMouseEnter={ () => this.onMouseEnterHandler() }
                 onMouseLeave={ () => this.onMouseLeaveHandler() }
                 onClick={ () => this.doParentCallBack(this.props.index)}>
                <hr className="ProcessStep-Horizontal-Rule"/>
                <div className="ProcessStep-Title-Container">
                    <div className="ProcessStep-Title">{t(`process.${this.props.name}.title`)}</div>
                    <div
                        className="ProcessStep-Arrow"
                    >
                        {
                            this.showArrow(this.props.index, this.state.isHover)
                        }
                    </div>
                </div>
                <Collapse isOpened={this.props.activeIndex === this.props.index}>
                    <div className="ProcessStep-Description">{t(`process.${this.props.name}.description`)}</div>
                </Collapse>
            </div>
        );
    }
}

export default withTranslation()(ProcessStep)