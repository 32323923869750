import React from 'react';

import './Clients.css';
import {withTranslation} from 'react-i18next';
import Client from "./Client/Client";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import {LeftArrow} from "../../containers/LeftArrow/LeftArrow";
import {RightArrow} from "../../containers/RightArrow/RightArrow";

function Clients({t}) {
    const ButtonGroup = ({next, previous}) => {
        return (
            <div className="Clients-carousel-button-group">
                <button onClick={() => previous()}>
                    <div className="Clients-Arrow-Left-Container">
                        <LeftArrow/>
                    </div>
                </button>
                <button onClick={() => next()}>
                    <div className="Clients-Arrow-Right-Container">
                        <RightArrow/>
                    </div>
                </button>
            </div>
        );
    };

    return (
        <div className="Clients-Container">
            <div className="Clients-Title">{t('clients.title')}</div>
            <Carousel
                additionalTransfrom={0}
                arrows={false}
                customButtonGroup={<ButtonGroup/>}
                autoPlay={true}
                autoPlaySpeed={4500}
                centerMode={false}
                className=""
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1025
                        },
                        items: 4,
                        partialVisibilityGutter: 40
                    },
                    tablet: {
                        breakpoint: {
                            max: 1200,
                            min: 900
                        },
                        items: 3,
                        partialVisibilityGutter: 30
                    },
                    tablet2: {
                        breakpoint: {
                            max: 900,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    }
                }}
            >
                <Client name="alphabet" left="-5px"/>
                <Client name="avata" left="-24px"/>
                <Client name="delhaize" left="-40px"/>
                <Client name="gatesoft" left="-17px"/>
                <Client name="ind" left="0px"/>
                <Client name="kpmg" left="-17px"/>
                <Client name="loomis" left="-3px"/>
                <Client name="vanderlande" left="-5px"/>
            </Carousel>
        </div>
    );
}

export default withTranslation()(Clients)
