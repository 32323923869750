import React from 'react';

import './Payoff.css';
import {withTranslation} from "react-i18next";

function Payoff({ t }) {
    return (
        <div className="Container">
            <div className="Payoff">{t('payoff.1')}</div>
            <div className="Payoff">{t('payoff.2')}</div>
        </div>
    );
}

export default withTranslation()(Payoff);