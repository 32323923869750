import React from 'react';

import './Contact.css';
import {withTranslation} from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import i18next from "i18next";
import Ticker from "react-ticker";

const Contact = () => {
    return (
        <div className="Contact-Container">
            <Ticker speed={6}>
                {({ index }) => (
                        <div className="Contact-Carpe-Diem">{i18next.t('contact.title')}</div>
                    )}
            </Ticker>
        </div>
    );
}

export default withTranslation()(Contact)