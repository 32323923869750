import React, {Component} from 'react'

import './Sidebar.css'
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import NavigationItems from "../../components/Navigation/NavigationItems/NavigationItems";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

export class Sidebar extends Component {

    changeTopic(topic) {
        this.props.onSelectTopic(topic)
    }

    render() {
        return (
            <div className="SideBar">
                <LanguageSelector/>
                <NavigationItems/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedTopic: state.menu.selectedTopic
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectTopic: (topic) => dispatch( actions.updateMenuTopic(topic))
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Sidebar );