import React from 'react'
import { ReactComponent as AlphabetSvg} from '../../assets/svgs/clients/Alphabet.svg';

const AlphabetLogo = () => (
    <div>
        <AlphabetSvg/>
    </div>
)

export default AlphabetLogo
