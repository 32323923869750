import React from 'react';

import './Header.css';

const header = () => (
    <header className="Header">
        <div className="HeaderName">Apprise</div>
    </header>
);

export default header;
